.dropdown__search {
  display: block;
  position: absolute;
  width: 100%;
  max-height: 200px;
  max-width: 300px;
  overflow-y: scroll;
  padding: 0;
  margin-top: 47px;
  box-sizing: border-box;
  border-top: none;
  background: white;
  border: 2px solid #d8d8d8;
}

.dropdown__item__search,
.dropdown__item__search--selected {
  /* display: block; */
  list-style: none;
  font-size: 14px;
  height: 45px;
  /* width: 200px; */
  padding: 15px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  /* padding-top: 20px; */
  z-index: 99999;
  background-color: white;
}

.dropdown__item__search--selected {
  background: #83d0f2;
}

.dropdown__item__search:hover,
.dropdown__item__search--selected:hover {
  background: #14191b;
}
